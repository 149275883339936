import PropTypes from 'prop-types';

const Error = ({ code, message }) => (
  <>
    <div className="error_container">
      <h1>{code}</h1>
      <p>{message}</p>
    </div>
    <style jsx>
      {`
        h1 {
          color: #e4e4e4;
          font-family: 'big_noodle_titling';
          font-size: 31px;
          letter-spacing: 0;
          line-height: 40px;
        }
        .error_container {
          background-color: black;
          color: #e4e4e4;
          font-family: 'big_noodle_titling';
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          min-height: 500px;
        }
      `}
    </style>
  </>
);

Error.propTypes = {
  code: PropTypes.number,
  message: PropTypes.string,
};

Error.defaultProps = {
  code: 404,
  message: 'The page you are looking for does not exist',
};

export default Error;
